import { Language } from "../LangSelector/types";
import { FooterLinkType } from "./types";
import { TwitterIcon, TelegramIcon, RedditIcon, InstagramIcon, GithubIcon, DiscordIcon, MediumIcon } from "../Svg";

export const footerLinks: FooterLinkType[] = [
];

export const socials = [
  {
    label: "Twitter",
    icon: TwitterIcon,
    href: "https://twitter.com/pegonetwork",
  },
  {
    label: "Telegram",
    icon: TelegramIcon,
    href: "https://t.me/pegonetwork2",
  },
  {
    label: "Github",
    icon: GithubIcon,
    href: "https://github.com/FstSwapDex",
  },
  {
    label: "Medium",
    icon: MediumIcon,
    href: "https://medium.com/@pegonetwork",
  },
];

export const langs: Language[] = [...Array(20)].map((_, i) => ({
  code: `en${i}`,
  language: `English${i}`,
  locale: `Locale${i}`,
}));
