import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 40 40" {...props}>
      <image id="BK" x="8" y="6" width="24" height="28" xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAcCAYAAAB75n/uAAACcElEQVRIiaXWW4iVVRgG4GeGbQpNGkFidw1YY4N3jeKFYUOkYjBeWFCEpgxdCHkgmAu1STsoKCMiHq4mMBAl8aIb6aCMOiQSncBKbAQvQpxAupAOWGMSa/gGtn97r7XDFzbsf63ve9+1vtP/tw3M/UuLmI+dWIDP8Raul1zbW+CehX34Hn14DK/haoi03Y/AG0G0ucHeDLyHn/Dy/xVYga9xAI8WDvEEjuMzLCwJPIWTOIWnC8RVLMWXOIzZVYEO7MFlrMqQ3IwD3M3YrI+wvjkl0BcLA4UTHsI8vIQunMjYzsRefJXK9G9Myxh/isHISRXPx4lfwAONnGuZRKfqeLtw0tO4hiV4pMH+74n8z8piet4a4ciRp/4Ywg9NyBM6apWrfRDhGM8Qi7C800IJ708C01MysAkXCw7L8D56CnafRJd/mwR6ca7gkML1blRQDql8N0bFTaJWIH8Q27ClQDyFNJfWxv9Jkdws6o/+aJVcCKTwHcSPWNlIoDdyMRyTsxnSzb/J7Hfj43qBThzDCBZlHH/G6jhIT0yAP5rY3m6PsZsSOIZXMsR3ojTT9Dxatz4Ua+mdcaPiM1GLa3ZniMXNBqNrG2E8htvDWFe/314gT+P3ObyaIU94FqNVcpkq+gWvRy5GMsSdEa6zeKbB/kNJYENlcXfEdDhDXIt8jMXtmuFWLWr2Al7ER7iUcUhYE+SPF+w+xI5aPHwXvxwWR7X1FuxGY8yfn7pqCV1R6/0Fu9Qf23GkfrEkMAdXCjb/YFdM2fR2vAel76JfsRxnmuynCnoyQvIf8lYEJuJ7J717U5enAfYbvoj+SCOjeX/gX36XfXnqkK9QAAAAAElFTkSuQmCC"/>
    </Svg>
  );
};

export default Icon;
