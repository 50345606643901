import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'
import { CHAIN_ID } from './networks'
import BigNumber from 'bignumber.js'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'FIST/OSK-DAO LP',
    lpAddresses: {
      97: '',
      56: '0xEFffCd30799636374a3a643Ac8EF615c6cAC916f',
    },
    token: serializedTokens.oskdao,
    quoteToken: serializedTokens.fist,
    multiplier: '10X',
  },
  {
    pid: 1,
    lpSymbol: 'FIST/USDT LP',
    lpAddresses: {
      97: '',
      56: '0xb4Ec801aED8C92F2E69589518AAa127afb37d8C9',
    },
    token: serializedTokens.fist,
    quoteToken: serializedTokens.usdt,
    multiplier: '0X',
  },
].filter((f) => !!f.lpAddresses[CHAIN_ID])

export default farms
