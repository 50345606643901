import Trans from 'components/Trans'
import { VaultKey } from 'state/types'
import { CHAIN_ID } from './networks'
import tokens, { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

export const vaultPoolConfig = {
  [VaultKey.CakeVault]: {
    name: <Trans>Auto CAKE</Trans>,
    description: <Trans>Automatic restaking</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 380000,
    tokenImage: {
      primarySrc: `/images/tokens/${tokens.cake.address}.png`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
  [VaultKey.IfoPool]: {
    name: 'IFO CAKE',
    description: <Trans>Stake CAKE to participate in IFOs</Trans>,
    autoCompoundFrequency: 1,
    gasLimit: 500000,
    tokenImage: {
      primarySrc: `/images/tokens/${tokens.cake.address}.png`,
      secondarySrc: `/images/tokens/ifo-pool-icon.svg`,
    },
  },
} as const

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.cake,
    contractAddress: {
      97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
      56: '0x1490ba374715e5eC086cFaDdf6061f2c581CD7E8',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 276,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ceek,
    contractAddress: {
      97: '',
      56: '0xED53944b1c0cEecDe1a413fDb4D0496e1a08ab58',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.8078',
    version: 3,
  },
  {
    sousId: 275,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.tinc,
    contractAddress: {
      97: '',
      56: '0x9593462fF51A14633b243Ba3d054A8183d057A02',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.4677',
    version: 3,
  },
  {
    sousId: 274,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.pex,
    contractAddress: {
      97: '',
      56: '0x641B1F2781B34a493E4308A0A3F1c7E042A9B952',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.3865',
    version: 3,
  },
  {
    sousId: 273,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.gmi,
    contractAddress: {
      97: '',
      56: '0x0D53E0f2Eb384777442e4EB813d8f5fAcC742206',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '17.939',
    version: 3,
  },
  {
    sousId: 272,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.froyo,
    contractAddress: {
      97: '',
      56: '0x84e3208578eE7db397a3d584d97Fea107b15bF35',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '3.404',
    version: 3,
  },
  {
    sousId: 271,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bsw,
    contractAddress: {
      97: '',
      56: '0x7092e029E4ce660F9AC081BF6D8a339BE602398b',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.157',
    version: 3,
  },
  {
    sousId: 270,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.duet,
    contractAddress: {
      97: '',
      56: '0xA581349F26dE887700045F9B7d148775d422fDA2',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.4861',
    version: 3,
  },
  {
    sousId: 269,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.gmt,
    contractAddress: {
      97: '',
      56: '0xe76a31cF974BA5819ce86cB4667a4bf05174bC59',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '4.629',
    version: 3,
  },
  {
    sousId: 268,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.xwg,
    contractAddress: {
      97: '',
      56: '0x6e0272A70075f6782F6842730107E9ABf74C5CC7',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '5.106',
    version: 3,
  },
  {
    sousId: 267,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.high,
    contractAddress: {
      97: '',
      56: '0x60c4998C058BaC8042712B54E7e43b892Ab0B0c4',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.09756',
    version: 3,
  },
  {
    sousId: 266,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.thg,
    contractAddress: {
      97: '',
      56: '0xD1D03A3D4C27884a8703Cdb78504737C9E9A159e',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.162',
    version: 3,
  },
  {
    sousId: 265,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.era,
    contractAddress: {
      97: '',
      56: '0x260F95f5b7FD8eda720ED9d0829164dE35B048ab',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.608',
    version: 3,
  },
  {
    sousId: 264,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.btt,
    contractAddress: {
      97: '',
      56: '0x346a1b672C5Cbb6AE21715428f77A0049B29B332',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '157829',
  },
  {
    sousId: 263,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ach,
    contractAddress: {
      97: '',
      56: '0xcD1Be742b04DB005e2C445A11BDe6d13DD9dd454',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '7.502',
  },
  {
    sousId: 261,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.sfund,
    contractAddress: {
      97: '',
      56: '0x80762101bd79D6e7A175E9678d05c7f815b8D7d7',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.06481',
  },
  {
    sousId: 260,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.raca,
    contractAddress: {
      97: '',
      56: '0xAaF43935a526DF88AB57FC69b1d80a8d35e1De82',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '204.2',
  },
  {
    sousId: 259,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ertha,
    contractAddress: {
      97: '',
      56: '0x921Ea7e12A66025F2BD287eDbff6dc5cEABd6477',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.585',
  },
  {
    sousId: 258,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.fuse,
    contractAddress: {
      97: '',
      56: '0xeAd7b8fc5F2E5672FAe9dCf14E902287F35CB169',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.19',
  },
  {
    sousId: 257,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.froyo,
    contractAddress: {
      97: '',
      56: '0x1c9E3972fdBa29b40954Bb7594Da6611998F8830',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '2.893',
  },
  {
    sousId: 256,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.aog,
    contractAddress: {
      97: '',
      56: '0xa34832efe74133763A85060a64103542031B0A7E',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.6435',
  },
  {
    sousId: 255,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.apx,
    contractAddress: {
      97: '',
      56: '0x92c07c325cE7b340Da2591F5e9CbB1F5Bab73FCF',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '3.035',
  },
  {
    sousId: 254,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bcoin,
    contractAddress: {
      97: '',
      56: '0x25ca61796d786014ffe15e42ac11c7721d46e120',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.1493',
  },
  {
    sousId: 253,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bcoin,
    contractAddress: {
      97: '',
      56: '0xad8F6A9d58012DCa2303226B287E80e5fE27eff0',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.1493',
  },
  {
    sousId: 252,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.insur,
    contractAddress: {
      97: '',
      56: '0x1A777aE604CfBC265807A46Db2d228d4CC84E09D',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.3281',
  },
  {
    sousId: 251,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.gm,
    contractAddress: {
      97: '',
      56: '0x09e727c83a75fFdB729280639eDBf947dB76EeB7',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '7893',
  },
  {
    sousId: 250,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.woop,
    contractAddress: {
      97: '',
      56: '0x2718D56aE2b8F08B3076A409bBF729542233E451',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.226',
  },
  {
    sousId: 249,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.high,
    contractAddress: {
      97: '',
      56: '0x2461ea28907A2028b2bCa40040396F64B4141004',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.02679',
  },
  {
    sousId: 247,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.dpt,
    contractAddress: {
      97: '',
      56: '0x1c0C7F3B07a42efb4e15679a9ed7e70B2d7Cc157',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '4.62962963',
  },
  {
    sousId: 246,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.thg,
    contractAddress: {
      97: '',
      56: '0x56Bfb98EBEF4344dF2d88c6b80694Cba5EfC56c8',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.03761',
  },
  {
    sousId: 245,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.idia,
    contractAddress: {
      97: '',
      56: '0x07984aBb7489CD436d27875c07Eb532d4116795a',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.1262',
  },
  {
    sousId: 244,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.xcv,
    contractAddress: {
      97: '',
      56: '0xF1fA41f593547E406a203b681df18acCC3971A43',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.7413',
  },
  {
    sousId: 243,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.nabox,
    contractAddress: {
      97: '',
      56: '0x13A40BFab005D9284f8938FBb70Bf39982580e4D',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1271',
  },
  {
    sousId: 242,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.santos,
    contractAddress: {
      97: '',
      56: '0x0914b2d9D4DD7043893DEF53ecFC0F1179F87d5c',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0578',
  },
  {
    sousId: 241,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.quidd,
    contractAddress: {
      97: '',
      56: '0xd97ee2bfe79a4d4ab388553411c462fbb536a88c',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.07471',
  },
  {
    sousId: 240,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.zoo,
    contractAddress: {
      97: '',
      56: '0x2EfE8772EB97B74be742d578A654AB6C95bF18db',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.4516',
  },
  {
    sousId: 239,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.sfund,
    contractAddress: {
      97: '',
      56: '0x7F103689cabe17C2F70DA6faa298045d72a943b8',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0311',
  },
  {
    sousId: 238,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.qi,
    contractAddress: {
      97: '',
      56: '0xbd52ef04DB1ad1c68A8FA24Fa71f2188978ba617',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.736',
  },
  {
    sousId: 237,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.kart,
    contractAddress: {
      97: '',
      56: '0x73bB10B89091f15e8FeD4d6e9EBa6415df6acb21',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.1897',
  },
  {
    sousId: 236,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.porto,
    contractAddress: {
      97: '',
      56: '0xdD52FAB121376432DBCBb47592742F9d86CF8952',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0578',
  },
  {
    sousId: 235,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.dvi,
    contractAddress: {
      97: '',
      56: '0x2b8751B7141Efa7a9917f9C6fea2CEA071af5eE7',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.2516',
  },
  {
    sousId: 234,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.jgn,
    contractAddress: {
      97: '',
      56: '0xfDFb4DbE94916F9f55dBC2c14Ea8B3e386eCD9F9',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.5233',
  },
  {
    sousId: 232,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.xwg,
    contractAddress: {
      97: '',
      56: '0x79f5f7DDADeFa0A9e850DFFC4fBa77e5172Fe701',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '2.3379',
  },
  {
    sousId: 231,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.dar,
    contractAddress: {
      97: '',
      56: '0x9b861A078B2583373A7a3EEf815bE1A39125Ae08',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '2.3148',
  },
  {
    sousId: 230,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.fina,
    contractAddress: {
      97: '',
      56: '0xa35caA9509a2337E22C54C929146D5F7f6515794',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.06944',
  },
  {
    sousId: 229,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bmon,
    contractAddress: {
      97: '',
      56: '0x6e63B2B96c77532ea7ec2B3D3BFA9C8e1d383f3C',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '2.0254',
  },
  {
    sousId: 228,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.dkt,
    contractAddress: {
      97: '',
      56: '0xFef4B7a0194159d89717Efa592384d42B28D3926',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.09838',
  },
  {
    sousId: 227,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ramp,
    contractAddress: {
      97: '',
      56: '0x2D26e4b9a5F19eD5BB7AF221DC02432D31DEB4dA',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.2152',
  },
  {
    sousId: 226,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.lazio,
    contractAddress: {
      97: '',
      56: '0xd008416c2c9cf23843bd179aa3cefedb4c8d1607',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0694',
  },
  {
    sousId: 225,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.sps,
    contractAddress: {
      97: '',
      56: '0xd9b63bb6c62fe2e9a641699a91e680994b8b0081',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.40046',
  },
  {
    sousId: 224,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.mcb,
    contractAddress: {
      97: '',
      56: '0xCc2D359c3a99d9cfe8e6F31230142efF1C828e6D',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.00706',
  },
  {
    sousId: 223,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.rpg,
    contractAddress: {
      97: '',
      56: '0x65C0940C50A3C98AEEc95a115Ae62E9804588713',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.01863',
  },
  {
    sousId: 222,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.beta,
    contractAddress: {
      97: '',
      56: '0x6f660c58723922c6f866a058199ff4881019b4b5',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.7361',
  },
  {
    sousId: 233,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.eternal,
    contractAddress: {
      97: '',
      56: '0xc28c400F2B675b25894FA632205ddec71E432288',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.002893',
  },
  {
    sousId: 221,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.nft,
    contractAddress: {
      97: '',
      56: '0x8d018823d13c56d62ffb795151a9e629c21e047b',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '173727',
  },
  {
    sousId: 220,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.chess,
    contractAddress: {
      97: '',
      56: '0x4D1Ec426d0d7fb6bF344Dd372d0502EDD71c8d88',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0861',
  },
  {
    sousId: 219,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.tlos,
    contractAddress: {
      97: '',
      56: '0xCB41a72067c227D6Ed7bc7CFAcd13eCe47Dfe5E9',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.3616',
  },
  {
    sousId: 218,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.stephero,
    contractAddress: {
      97: '',
      56: '0xcecba456fefe5b18d43df23419e7ab755b436655',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.1851',
  },
  {
    sousId: 217,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bp,
    contractAddress: {
      97: '',
      56: '0x8ed7acf12b08274d5cdaf03d43d0e54bcbdd487e',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.217',
  },
  {
    sousId: 216,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.cvp,
    contractAddress: {
      97: '',
      56: '0xC4b15117BC0be030c20754FF36197641477af5d1',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.14',
  },
  {
    sousId: 215,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.qbt,
    contractAddress: {
      97: '',
      56: '0xB72dEf58D0832f747d6B7197471Fe20AeA7EB463',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.412',
  },
  {
    sousId: 214,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.naos,
    contractAddress: {
      97: '',
      56: '0xb38b78529bCc895dA16CE2978D6cD6C56e8CfFC3',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.1736',
  },
  {
    sousId: 213,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.pha,
    contractAddress: {
      97: '',
      56: '0x2E101b5F7f910F2609e5AcE5f43bD274b1DE09AA',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.28',
  },
  {
    sousId: 212,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bel,
    contractAddress: {
      97: '',
      56: '0x52733Ad7b4D09BF613b0389045e33E2F287afa04',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.101',
  },
  {
    sousId: 211,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ramp,
    contractAddress: {
      97: '',
      56: '0x401b9b97bdbc3197c1adfab9652dc78040bd1e13',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.9837',
  },
  {
    sousId: 210,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.pots,
    contractAddress: {
      97: '',
      56: '0xBeDb490970204cb3CC7B0fea94463BeD67d5364D',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0868',
  },
  {
    sousId: 209,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.tusd,
    contractAddress: {
      97: '',
      56: '0xb6e510ae2da1ab4e350f837c70823ab75091780e',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.5787',
  },
  {
    sousId: 208,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.revv,
    contractAddress: {
      97: '',
      56: '0x8aa5b2c67852ed5334c8a7f0b5eb0ef975106793',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.331',
  },
  {
    sousId: 207,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bttold,
    contractAddress: {
      97: '',
      56: '0x3b804460c3c62f0f565af593984159f13b1ac976',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '263',
  },
  {
    sousId: 206,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.skill,
    contractAddress: {
      97: '',
      56: '0x455f4d4cc4d6ca15441a93c631e82aaf338ad843',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.00289',
  },
  {
    sousId: 205,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.win,
    contractAddress: {
      97: '',
      56: '0xde4aef42bb27a2cb45c746acde4e4d8ab711d27c',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1815.39',
  },
  {
    sousId: 204,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.if,
    contractAddress: {
      97: '',
      56: '0x57d3524888ded4085d9124a422f13b27c8a43de7',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.02835',
  },
  {
    sousId: 203,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.sps,
    contractAddress: {
      97: '',
      56: '0xb56299d8fbf46c509014b103a164ad1fc65ea222',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '12.86',
  },
  {
    sousId: 202,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.c98,
    contractAddress: {
      97: '',
      56: '0x5e49531BA07bE577323e55666D46C6217164119E',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.7361',
  },
  {
    sousId: 201,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.axs,
    contractAddress: {
      97: '',
      56: '0xBB472601B3CB32723d0755094BA80B73F67f2AF3',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.00744',
  },
  {
    sousId: 200,
    stakingToken: serializedTokens.axs,
    earningToken: serializedTokens.cake,
    contractAddress: {
      97: '',
      56: '0x583A36816F3b8401C4fdf682203E0caDA6997740',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.00992',
  },
  {
    sousId: 199,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.pmon,
    contractAddress: {
      97: '',
      56: '0x28050e8f024e05f9ddbef5f60dd49f536dba0cf0',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.02696',
  },
  {
    sousId: 198,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.trx,
    contractAddress: {
      97: '',
      56: '0xb2b62f88ab82ed0bb4ab4da60d9dc9acf9e816e5',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '9.6643',
  },
  {
    sousId: 197,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.chess,
    contractAddress: {
      97: '',
      56: '0xd1812e7e28c39e78727592de030fc0e7c366d61a',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.4286',
  },
  {
    sousId: 196,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.titan,
    contractAddress: {
      97: '',
      56: '0x97058cf9b36c9ef1622485cef22e72d6fea32a36',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.08912',
  },
  {
    sousId: 195,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.harmony,
    contractAddress: {
      97: '',
      56: '0xe595456846155e23b24cc9cbee910ee97027db6d',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '3.0092',
  },
  {
    sousId: 194,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.mask,
    contractAddress: {
      97: '',
      56: '0xae611c6d4d3ca2cee44cd34eb7aac29d5a387fcf',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.06423',
  },
  {
    sousId: 193,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.dvi,
    contractAddress: {
      97: '',
      56: '0x135827eaf9746573c0b013f18ee12f138b9b0384',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.7233',
  },
  {
    sousId: 192,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.gum,
    contractAddress: {
      97: '',
      56: '0x09b8a5f51c9e245402057851ada274174fa00e2a',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.67129',
  },
  {
    sousId: 191,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.adx,
    contractAddress: {
      97: '',
      56: '0x53a2d1db049b5271c6b6db020dba0e8a7c4eb90d',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.7523',
  },
  {
    sousId: 190,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.suter,
    contractAddress: {
      97: '',
      56: '0x4da8da81647ee0aa7350e9959f3e4771eb753da0',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '69.9074',
  },
  {
    sousId: 189,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bscpad,
    contractAddress: {
      97: '',
      56: '0x0446b8f8474c590d2249a4acdd6eedbc2e004bca',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.3877',
  },
  {
    sousId: 188,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.rabbit,
    contractAddress: {
      97: '',
      56: '0x391240A007Bfd8A59bA74978D691219a76c64c5C',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '3.993',
  },
  {
    sousId: 187,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.waultx,
    contractAddress: {
      97: '',
      56: '0x017DEa5C58c2Bcf57FA73945073dF7AD4052a71C',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '7.9108',
  },
  {
    sousId: 186,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.wex,
    contractAddress: {
      97: '',
      56: '0x6Bd94783caCef3fb7eAa9284f1631c464479829f',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '11.574',
  },
  {
    sousId: 185,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.form,
    contractAddress: {
      97: '',
      56: '0x7c71723fB1F9Cfb250B702cfc4eBd5D9Ab2E83d9',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.4467',
  },
  {
    sousId: 184,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.orbs,
    contractAddress: {
      97: '',
      56: '0x9C8813d7D0A61d30610a7A5FdEF9109e196a3D77',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '3.8946',
  },
  {
    sousId: 183,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.$dg,
    contractAddress: {
      97: '',
      56: '0xa07a91da6d10173f33c294803684bceede325957',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.00135',
  },
  {
    sousId: 182,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.woo,
    contractAddress: {
      97: '',
      56: '0x88c321d444c88acf3e747dc90f20421b97648903',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.3854',
  },
  {
    sousId: 181,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.woo,
    contractAddress: {
      97: '',
      56: '0x3c7234c496d76133b48bd6a342e7aea4f8d87fc8',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0003854',
  },
  {
    sousId: 180,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.htb,
    contractAddress: {
      97: '',
      56: '0x64473c33c360f315cab38674f1633505d1d8dcb2',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '5.2083',
  },
  {
    sousId: 179,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.jgn,
    contractAddress: {
      97: '',
      56: '0x5cc7a19a50be2a6b2540ebcd55bd728e732e59c3',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.1001',
  },
  {
    sousId: 178,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.dft,
    contractAddress: {
      97: '',
      56: '0x2666e2494e742301ffc8026e476acc1710a775ed',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.7361',
  },
  {
    sousId: 177,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.hai,
    contractAddress: {
      97: '',
      56: '0x6ac2213F09A404c86AFf506Aa51B6a5BF1F6e24E',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.3078',
  },
  {
    sousId: 176,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.o3,
    contractAddress: {
      97: '',
      56: '0x35BD47263f7E57368Df76339903C53bAa99076e1',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.107638',
  },
  {
    sousId: 175,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ampl,
    contractAddress: {
      97: '',
      56: '0x62dEc3A560D2e8A84D30752bA454f97b26757877',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.398',
  },
  {
    sousId: 174,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.oddz,
    contractAddress: {
      97: '',
      56: '0x44d1f81e80e43e935d66d65874354ef91e5e49f6',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.4843',
  },
  {
    sousId: 173,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bondly,
    contractAddress: {
      97: '',
      56: '0x4ea43fce546975aae120c9eeceb172500be4a02b',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.6741',
  },
  {
    sousId: 172,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.marsh,
    contractAddress: {
      97: '',
      56: '0x567fd708e788e51b68666b9310ee9df163d60fae',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.1108',
  },
  {
    sousId: 171,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.mbox,
    contractAddress: {
      97: '',
      56: '0x36f9452083fc9bc469a31e7966b873f402292433',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.4803',
  },
  {
    sousId: 170,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ata,
    contractAddress: {
      97: '',
      56: '0xc612680457751d0d01b5d901ad08132a3b001900',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.7361',
  },
  {
    sousId: 169,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.mx,
    contractAddress: {
      97: '',
      56: '0x336bcd59f2b6eb7221a99f7a50fd03c6bf9a306b',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.27777',
  },
  {
    sousId: 168,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bcfx,
    contractAddress: {
      97: '',
      56: '0x2b3974dda76b2d408b7d680a27fbb0393e3cf0e1',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.6944',
  },
  {
    sousId: 167,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.qkc,
    contractAddress: {
      97: '',
      56: '0xfa67f97eeee6de55d179ecabbfe701f27d9a1ed9',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '15.9143',
  },
  {
    sousId: 166,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ktn,
    contractAddress: {
      97: '',
      56: '0x48852322a185dc5fc733ff8c8d7c6dcbd2b3b2a2',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.01215',
  },
  {
    sousId: 165,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.mtrg,
    contractAddress: {
      97: '',
      56: '0xf4d0f71698f58f221911515781b05e808a8635cb',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.05613',
  },
  {
    sousId: 164,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.tusd,
    contractAddress: {
      97: '',
      56: '0x9dceb1d92f7e0361d0766f3d98482424df857654',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.5787',
  },
  {
    sousId: 163,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.vrt,
    contractAddress: {
      97: '',
      56: '0xb77f1425ec3a7c78b1a1e892f72332c8b5e8ffcb',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '40.5092',
  },
  {
    sousId: 162,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.kalm,
    contractAddress: {
      97: '',
      56: '0xb9ff4da0954b300542e722097671ead8cf337c17',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0868',
  },
  {
    sousId: 161,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ez,
    contractAddress: {
      97: '',
      56: '0xb19395702460261e51edf7a7b130109c64f13af9',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.01736',
  },
  {
    sousId: 160,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.kalm,
    contractAddress: {
      97: '',
      56: '0x6e113ecb9ff2d271140f124c2cc5b5e4b5700c9f',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    isFinished: true,
    tokenPerBlock: '0.00868',
  },
  {
    sousId: 159,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.popen,
    contractAddress: {
      97: '',
      56: '0x7baf1763ce5d0da8c9d85927f08a8be9c481ce50',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.19097',
  },
  {
    sousId: 158,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.lien,
    contractAddress: {
      97: '',
      56: '0x2b8d6c9c62bfc1bed84724165d3000e61d332cab',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.023148',
  },
  {
    sousId: 157,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.well,
    contractAddress: {
      97: '',
      56: '0x8a06ff2748edcba3fb4e44a6bfda4e46769e557b',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.2025',
    sortOrder: 999,
  },
  {
    sousId: 156,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.deri,
    contractAddress: {
      97: '',
      56: '0x3eba95f5493349bbe0cad33eaae05dc6a7e26b90',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '1.6087',
    sortOrder: 999,
  },
  {
    sousId: 155,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.chr,
    contractAddress: {
      97: '',
      56: '0x593edbd14a5b7eec828336accca9c16cc12f04be',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '1.655',
    sortOrder: 999,
  },
  {
    sousId: 154,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.cyc,
    contractAddress: {
      97: '',
      56: '0xD714738837944C3c592477249E8edB724A76e068',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00015914',
    sortOrder: 999,
  },
  {
    sousId: 153,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.xend,
    contractAddress: {
      97: '',
      56: '0x8ea9f2482b2f7b12744a831f81f8d08714adc093',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.31828',
    sortOrder: 999,
    isFinished: false,
  },
  {
    sousId: 152,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.hget,
    contractAddress: {
      97: '',
      56: '0x8e8125f871eb5ba9d55361365f5391ab437f9acc',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.03553',
    sortOrder: 999,
    isFinished: false,
  },
  {
    sousId: 151,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.hotcross,
    contractAddress: {
      97: '',
      56: '0x0e09205e993f78cd5b3a5df355ae98ee7d0b5834',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '3.4722',
    isFinished: false,
  },
  {
    sousId: 150,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.rfox,
    contractAddress: {
      97: '',
      56: '0xf9f00d41b1f4b3c531ff750a9b986c1a530f33d9',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.7361',
    isFinished: false,
  },
  {
    sousId: 149,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.wmass,
    contractAddress: {
      97: '',
      56: '0x4Af531EcD50167a9402Ce921ee6436dd4cFC04FD',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.24942',
    isFinished: false,
  },
  {
    sousId: 148,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ubxt,
    contractAddress: {
      97: '',
      56: '0x9b4bac2d8f69853aa29cb45478c77fc54532ac22',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '2.4074',
    isFinished: false,
  },
  {
    sousId: 147,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.btr,
    contractAddress: {
      97: '',
      56: '0x20ee70a07ae1b475cb150dec27930d97915726ea',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '2.8935',
    isFinished: false,
  },
  {
    sousId: 146,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.τdoge,
    contractAddress: {
      97: '',
      56: '0x017556dffb8c6a52fd7f4788adf6fb339309c81b',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.868',
    isFinished: false,
  },
  {
    sousId: 145,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.pmon,
    contractAddress: {
      97: '',
      56: '0xdaa711ecf2ac0bff5c82fceeae96d0008791cc49',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.01261',
    isFinished: false,
  },
  {
    sousId: 144,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.one,
    contractAddress: {
      97: '',
      56: '0x74af842ecd0b6588add455a47aa21ed9ba794108',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '57.87',
    isFinished: false,
  },
  {
    sousId: 143,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.fine,
    contractAddress: {
      97: '',
      56: '0x42d41749d6e9a1c5b47e27f690d4531f181b2159',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.1342',
    isFinished: false,
  },
  {
    sousId: 142,
    stakingToken: serializedTokens.doge,
    earningToken: serializedTokens.cake,
    contractAddress: {
      97: '',
      56: '0xbebd44824631b55991fa5f2bf5c7a4ec96ff805b',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.01388',
    isFinished: false,
  },
  {
    sousId: 141,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bmxx,
    contractAddress: {
      97: '',
      56: '0x55131f330c886e3f0cae389cedb23766ac9aa3ed',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.01331',
    isFinished: false,
  },
  {
    sousId: 140,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.oin,
    contractAddress: {
      97: '',
      56: '0x01453a74a94687fa3f99b80762435855a13664f4',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.3472',
    isFinished: false,
  },
  {
    sousId: 139,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.hyfi,
    contractAddress: {
      97: '',
      56: '0x0032ceb978fe5fc8a5d5d6f5adfc005e76397e29',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '2.8935',
    isFinished: false,
  },
  {
    sousId: 138,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.kun,
    contractAddress: {
      97: '',
      56: '0x439b46d467402cebc1a2fa05038b5b696b1f4417',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.23148',
    isFinished: false,
  },
  {
    sousId: 137,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.math,
    contractAddress: {
      97: '',
      56: '0x377ae5f933aa4cfa41fa03e2cae8a2befccf53b2',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.15798',
    isFinished: false,
  },
  {
    sousId: 136,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ust,
    contractAddress: {
      97: '',
      56: '0xce3ebac3f549ebf1a174a6ac3b390c179422b5f6',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.17361',
    isFinished: false,
  },
  {
    sousId: 135,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.win,
    contractAddress: {
      97: '',
      56: '0xd26dec254c699935c286cd90e9841dcabf1af72d',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '462.96',
    isFinished: false,
  },
  {
    sousId: 134,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.trx,
    contractAddress: {
      97: '',
      56: '0x93e2867d9b74341c2d19101b7fbb81d6063cca4d',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '4.0509',
    isFinished: false,
  },
  {
    sousId: 133,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bttold,
    contractAddress: {
      97: '',
      56: '0x3b644e44033cff70bd6b771904225f3dd69dfb6d',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '79.86',
    isFinished: false,
  },
  {
    sousId: 132,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.lmt,
    contractAddress: {
      97: '',
      56: '0x0a687d7b951348d681f7ed5eea84c0ba7b9566dc',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.66145',
    isFinished: false,
  },
  {
    sousId: 131,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.pnt,
    contractAddress: {
      97: '',
      56: '0x417df1c0e6a498eb1f2247f99032a01d4fafe922',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.09548',
    isFinished: false,
  },
  {
    sousId: 130,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.xmark,
    contractAddress: {
      97: '',
      56: '0xdc8943d806f9dd64312d155284abf780455fd345',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0298',
    isFinished: false,
  },
  {
    sousId: 129,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ramp,
    contractAddress: {
      97: '',
      56: '0xa90a894e5bc20ab2be46c7e033a38f8b8eaa771a',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.4861',
    isFinished: true,
  },
  {
    sousId: 128,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.hakka,
    contractAddress: {
      97: '',
      56: '0x34ac807e34e534fe426da1e11f816422774aae1c',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '3.4722',
    isFinished: false,
  },
  {
    sousId: 127,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.pbtc,
    contractAddress: {
      97: '',
      56: '0x31fa2f516b77c4273168b284ac6d9def5aa6dafb',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0000031',
    isFinished: false,
  },
  {
    sousId: 126,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.lien,
    contractAddress: {
      97: '',
      56: '0x7112f8988f075c7784666ab071927ae4109a8076',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.003472',
    isFinished: false,
  },
  {
    sousId: 125,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bbadger,
    contractAddress: {
      97: '',
      56: '0x126dfbcef85c5bf335f8be99ca4006037f417892',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.00265278',
    isFinished: false,
  },
  {
    sousId: 124,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bdigg,
    contractAddress: {
      97: '',
      56: '0x4f0ad2332b1f9983e8f63cbee617523bb7de5031',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.00000403',
    isFinished: false,
  },
  {
    sousId: 123,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bondly,
    contractAddress: {
      97: '',
      56: '0x9483ca44324de06802576866b9d296f7614f45ac',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.4629',
    isFinished: false,
  },
  {
    sousId: 122,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.xed,
    contractAddress: {
      97: '',
      56: '0x72ceec6e2a142678e703ab0710de78bc819f4ce0',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.3472',
    isFinished: false,
  },
  {
    sousId: 121,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.cgg,
    contractAddress: {
      97: '',
      56: '0x1c6ed21d3313822ae73ed0d94811ffbbe543f341',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.10918',
    isFinished: false,
  },
  {
    sousId: 120,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.perl,
    contractAddress: {
      97: '',
      56: '0x1ac0d0333640f57327c83053c581340ebc829e30',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.1574',
    isFinished: false,
  },
  {
    sousId: 119,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.arpa,
    contractAddress: {
      97: '',
      56: '0xc707e5589aeb1dc117b0bb5a3622362f1812d4fc',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.949',
    isFinished: false,
  },
  {
    sousId: 118,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.oddz,
    contractAddress: {
      97: '',
      56: '0x22106cdcf9787969e1672d8e6a9c03a889cda9c5',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.11284',
    isFinished: false,
  },
  {
    sousId: 117,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.dexe,
    contractAddress: {
      97: '',
      56: '0x999b86e8bba3d4f05afb8155963999db70afa97f',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.009837',
    isFinished: true,
  },
  {
    sousId: 116,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.dfd,
    contractAddress: {
      97: '',
      56: '0xAF3EfE5fCEeBc603Eada6A2b0172be11f7405102',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.46296',
    isFinished: false,
  },
  {
    sousId: 115,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.alpaca,
    contractAddress: {
      97: '',
      56: '0xf73fdeb26a8c7a4abf3809d3db11a06ba5c13d0e',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.22743',
    isFinished: false,
  },
  {
    sousId: 114,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.τbtc,
    contractAddress: {
      97: '',
      56: '0xaac7171afc93f4b75e1268d208040b152ac65e32',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.00000608',
    isFinished: false,
  },
  {
    sousId: 113,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.τbtc,
    contractAddress: {
      97: '',
      56: '0x2c6017269b4324d016ca5d8e3267368652c18905',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.00000608',
    isFinished: true,
  },
  {
    sousId: 112,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.swingby,
    contractAddress: {
      97: '',
      56: '0x675434c68f2672c983e36cf10ed13a4014720b79',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.21527',
  },
  {
    sousId: 111,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.xed,
    contractAddress: {
      97: '',
      56: '0x05d6c2d1d687eacfb5e6440d5a3511e91f2201a8',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.3472',
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 110,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.hakka,
    contractAddress: {
      97: '',
      56: '0xd623a32da4a632ce01766c317d07cb2cad56949b',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '3.4722',
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 109,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.cgg,
    contractAddress: {
      97: '',
      56: '0xdf75f38dbc98f9f26377414e567abcb8d57cca33',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.10918',
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 108,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.mix,
    contractAddress: {
      97: '',
      56: '0xce64a930884b2c68cd93fc1c7c7cdc221d427692',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.3721',
  },
  {
    sousId: 107,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.suter,
    contractAddress: {
      97: '',
      56: '0xc1E70edd0141c454b834Deac7ddDeA413424aEf9',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '15.3356',
  },
  {
    sousId: 106,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.broobee,
    contractAddress: {
      97: '',
      56: '0x189d8228CdfDc404Bd9e5bD65ff958cb5fd8855c',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '14.4675',
  },
  {
    sousId: 105,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.hzn,
    contractAddress: {
      97: '',
      56: '0x0196c582216e2463f052E2B07Ef8667Bec9Fb17a',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.6944',
  },
  {
    sousId: 104,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.alpa,
    contractAddress: {
      97: '',
      56: '0x8f84106286c9c8A42bc3555C835E6e2090684ab7',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.23495',
  },
  {
    sousId: 103,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.perl,
    contractAddress: {
      97: '',
      56: '0xa8d32b31ECB5142f067548Bf0424389eE98FaF26',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.1574',
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 102,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.tlm,
    contractAddress: {
      97: '',
      56: '0xC59aa49aE508050c2dF653E77bE13822fFf02E9A',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '17.361',
  },
  {
    sousId: 101,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.jgn,
    contractAddress: {
      97: '',
      56: '0x14AeA62384789EDA98f444cCb970F6730877d3F9',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.08796',
  },
  {
    sousId: 100,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.eps,
    contractAddress: {
      97: '',
      56: '0xebb87dF24D65977cbe62538E4B3cFBD5d0308642',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.07716',
  },
  {
    sousId: 99,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.arpa,
    contractAddress: {
      97: '',
      56: '0x40918EF8efFF4aA061656013a81E0e5A8A702eA7',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.949',
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 98,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.itam,
    contractAddress: {
      97: '',
      56: '0x44eC1B26035865D9A7C130fD872670CD7Ebac2bC',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '3.096',
  },
  {
    sousId: 97,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bondly,
    contractAddress: {
      97: '',
      56: '0x1329ad151dE6C441184E32E108401126AE850937',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.4629',
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 96,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.tko,
    contractAddress: {
      97: '',
      56: '0x9bbDc92474a7e7321B78dcDA5EF35f4981438760',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.1574',
  },
  {
    sousId: 95,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.apys,
    contractAddress: {
      97: '',
      56: '0x46530d79b238f809e80313e73715b160c66677aF',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.09953',
  },
  {
    sousId: 94,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.hoo,
    contractAddress: {
      97: '',
      56: '0x47fD853D5baD391899172892F91FAa6d0cd8A2Aa',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.489',
  },
  {
    sousId: 93,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.oddz,
    contractAddress: {
      97: '',
      56: '0xe25aB6F05BBF6C1be953BF2d7df15B3e01b8e5a5',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.11284',
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 92,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.easy,
    contractAddress: {
      97: '',
      56: '0xEB8Fd597921E3Dd37B0F103a2625F855e2C9b9B5',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0072338',
  },
  {
    sousId: 91,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.nrv,
    contractAddress: {
      97: '',
      56: '0xABFd8d1942628124aB971937154f826Bce86DcbC',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.15046',
  },
  {
    sousId: 90,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.dego,
    contractAddress: {
      97: '',
      56: '0x526d3c204255f807C95a99b69596f2f9f72345e5',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.00405',
  },
  {
    sousId: 89,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.gum,
    contractAddress: {
      97: '',
      56: '0xAa2082BeE04fc518300ec673F9497ffa6F669dB8',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.08912',
  },
  {
    sousId: 88,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.pbtc,
    contractAddress: {
      97: '',
      56: '0x9096625Bc0d36F5EDa6d44e511641667d89C28f4',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0000031',
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 87,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.dft,
    contractAddress: {
      97: '',
      56: '0x78BD4dB48F8983c3C36C8EAFbEF38f6aC7B55285',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.6811',
  },
  {
    sousId: 86,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.swth,
    contractAddress: {
      97: '',
      56: '0x35418e14F5aA615C4f020eFBa6e01C5DbF15AdD2',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '2.3148',
  },
  {
    sousId: 85,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.lien,
    contractAddress: {
      97: '',
      56: '0x3c7cC49a35942fbD3C2ad428a6c22490cd709d03',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.003472',
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 84,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.zil,
    contractAddress: {
      97: '',
      56: '0xF795739737ABcFE0273f4Dced076460fdD024Dd9',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.0995',
  },
  {
    sousId: 83,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.pcws,
    contractAddress: {
      97: '',
      56: '0x06FF8960F7F4aE572A3f57FAe77B2882BE94Bf90',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.00434',
  },
  {
    sousId: 82,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bbadger,
    contractAddress: {
      97: '',
      56: '0xe4dD0C50fb314A8B2e84D211546F5B57eDd7c2b9',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.00265278',
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 81,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bdigg,
    contractAddress: {
      97: '',
      56: '0xb627A7e33Db571bE792B0b69c5C2f5a8160d5500',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.00000403',
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 80,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.lto,
    contractAddress: {
      97: '',
      56: '0xadBfFA25594AF8Bc421ecaDF54D057236a99781e',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.2893',
  },
  {
    sousId: 79,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.mir,
    contractAddress: {
      97: '',
      56: '0x3e31488f08EBcE6F2D8a2AA512aeFa49a3C7dFa7',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.01273',
    sortOrder: 999,
    isFinished: false,
  },
  {
    sousId: 78,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.mir,
    contractAddress: {
      97: '',
      56: '0x453a75908fb5a36d482d5f8fe88eca836f32ead5',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.01273',
    sortOrder: 999,
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 77,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.trade,
    contractAddress: {
      97: '',
      56: '0x509C99D73FB54b2c20689708b3F824147292D38e',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.4484',
    sortOrder: 999,
    isFinished: false,
  },
  {
    sousId: 76,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.dusk,
    contractAddress: {
      97: '',
      56: '0xF1bd5673Ea4a1C415ec84fa3E402F2F7788E7717',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.4629',
    sortOrder: 999,
    isFinished: false,
  },
  {
    sousId: 75,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bifi,
    contractAddress: {
      97: '',
      56: '0xB4C68A1C565298834360BbFF1652284275120D47',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00007234',
    sortOrder: 999,
    isFinished: false,
  },
  {
    sousId: 74,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.txl,
    contractAddress: {
      97: '',
      56: '0x153e62257F1AAe05d5d253a670Ca7585c8D3F94F',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.434027',
    sortOrder: 999,
    isFinished: false,
  },
  {
    sousId: 73,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.cos,
    contractAddress: {
      97: '',
      56: '0xF682D186168b4114ffDbF1291F19429310727151',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '5.787',
    sortOrder: 999,
    isFinished: false,
  },
  {
    sousId: 72,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bunny,
    contractAddress: {
      97: '',
      56: '0xaDdAE5f4dB84847ac9d947AED1304A8e7D19f7cA',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00289',
    sortOrder: 999,
    isFinished: false,
  },
  {
    sousId: 71,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.alice,
    contractAddress: {
      97: '',
      56: '0x4C32048628D0d32d4D6c52662FB4A92747782B56',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.14467',
    sortOrder: 999,
    isFinished: false,
  },
  {
    sousId: 70,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.for,
    contractAddress: {
      97: '',
      56: '0x47642101e8D8578C42765d7AbcFd0bA31868c523',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '2.8935',
    sortOrder: 999,
    isFinished: false,
  },
  {
    sousId: 69,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bux,
    contractAddress: {
      97: '',
      56: '0x07F8217c68ed9b838b0b8B58C19c79bACE746e9A',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.5787',
    sortOrder: 999,
    isFinished: false,
  },
  {
    sousId: 68,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.nuls,
    contractAddress: {
      97: '',
      56: '0x580DC9bB9260A922E3A4355b9119dB990F09410d',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0868',
    sortOrder: 999,
    isFinished: false,
  },
  {
    sousId: 67,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.belt,
    contractAddress: {
      97: '',
      56: '0x6f0037d158eD1AeE395e1c12d21aE8583842F472',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00868',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 66,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ramp,
    contractAddress: {
      97: '',
      56: '0x423382f989C6C289c8D441000e1045e231bd7d90',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.4861',
    sortOrder: 999,
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 65,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bfi,
    contractAddress: {
      97: '',
      56: '0x0A595623b58dFDe6eB468b613C11A7A8E84F09b9',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0001157',
    sortOrder: 999,
    isFinished: false,
  },
  {
    sousId: 64,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.dexe,
    contractAddress: {
      97: '',
      56: '0x9E6dA246d369a41DC44673ce658966cAf487f7b2',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.009837',
    sortOrder: 999,
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 63,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bel,
    contractAddress: {
      97: '',
      56: '0x2C0f449387b15793B9da27c2d945dBed83ab1B07',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0549',
    sortOrder: 999,
    isFinished: false,
  },
  {
    sousId: 62,
    stakingToken: serializedTokens.tpt,
    earningToken: serializedTokens.cake,
    contractAddress: {
      97: '',
      56: '0x0c3D6892aa3b23811Af3bd1bbeA8b0740E8e4528',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0462',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 61,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.tpt,
    contractAddress: {
      97: '',
      56: '0x75C91844c5383A68b7d3A427A44C32E3ba66Fe45',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '3.616',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 60,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.watch,
    contractAddress: {
      97: '',
      56: '0xC58954199E268505fa3D3Cb0A00b7207af8C2D1d',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.3472',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 59,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.xmark,
    contractAddress: {
      97: '',
      56: '0xA5137e08C48167E363Be8Ec42A68f4F54330964E',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0413',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 58,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bmxx,
    contractAddress: {
      97: '',
      56: '0x6F31B87f51654424Ce57E9F8243E27ed13846CDB',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00248',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 57,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.iotx,
    contractAddress: {
      97: '',
      56: '0xCE54BA909d23B9d4BE0Ff0d84e5aE83F0ADD8D9a',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '6.365',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 56,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bor,
    contractAddress: {
      97: '',
      56: '0x3e677dC00668d69c2A7724b9AFA7363e8A56994e',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.000395',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 55,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bopen,
    contractAddress: {
      97: '',
      56: '0x5Ac8406498dC1921735d559CeC271bEd23B294A7',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0723',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 54,
    stakingToken: serializedTokens.sushi,
    earningToken: serializedTokens.cake,
    contractAddress: {
      97: '',
      56: '0xb69b6e390cba1F68442A886bC89E955048DAe7E3',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0367',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 53,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.dodo,
    contractAddress: {
      97: '',
      56: '0xae3001ddb18A6A57BEC2C19D71680437CA87bA1D',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0578',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 52,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.swingby,
    contractAddress: {
      97: '',
      56: '0x02aa767e855b8e80506fb47176202aA58A95315a',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.13',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 51,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bry,
    contractAddress: {
      97: '',
      56: '0x1c736F4FB20C7742Ee83a4099fE92abA61dFca41',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.1157',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 50,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.zee,
    contractAddress: {
      97: '',
      56: '0x02861B607a5E87daf3FD6ec19DFB715F1b371379',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.1736',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 49,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.swgb,
    contractAddress: {
      97: '',
      56: '0x73e4E8d010289267dEe3d1Fc48974B60363963CE',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.899',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 48,
    stakingToken: serializedTokens.comp,
    earningToken: serializedTokens.cake,
    contractAddress: {
      97: '',
      56: '0xE0565fBb109A3f3f8097D8A9D931277bfd795072',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.055',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 47,
    stakingToken: serializedTokens.comp,
    earningToken: serializedTokens.cake,
    contractAddress: {
      97: '',
      56: '0xc3693e3cbc3514d5d07EA5b27A721F184F617900',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.55',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 46,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.sfp,
    contractAddress: {
      97: '',
      56: '0x2B02d43967765b18E31a9621da640588f3550EFD',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.6',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 45,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.lina,
    contractAddress: {
      97: '',
      56: '0x212bb602418C399c29D52C55100fD6bBa12bea05',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.983',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 44,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.lina,
    contractAddress: {
      97: '',
      56: '0x04aE8ca68A116278026fB721c06dCe709eD7013C',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0983',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 43,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.lit,
    contractAddress: {
      97: '',
      56: '0x1714bAAE9DD4738CDEA07756427FA8d4F08D9479',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.231',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 42,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.hget,
    contractAddress: {
      97: '',
      56: '0xcCD0b93cC6ce3dC6dFaA9DB68f70e5C8455aC5bd',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0138',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 41,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bdo,
    contractAddress: {
      97: '',
      56: '0x9cB24e9460351bC51d4066BC6AEd1F3809b02B78',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.075',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 40,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.egld,
    contractAddress: {
      97: '',
      56: '0x2dcf4cDFf4Dd954683Fe0a6123077f8a025b66cF',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.001215',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 39,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ust,
    contractAddress: {
      97: '',
      56: '0x6EFa207ACdE6e1caB77c1322CbdE9628929ba88F',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.1157',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 38,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.wsote,
    contractAddress: {
      97: '',
      56: '0xD0b738eC507571176D40f28bd56a0120E375f73a',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.23',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 37,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.front,
    contractAddress: {
      97: '',
      56: '0xf7a31366732F08E8e6B88519dC3E827e04616Fc9',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.2546',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 36,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.helmet,
    contractAddress: {
      97: '',
      56: '0x9F23658D5f4CEd69282395089B0f8E4dB85C6e79',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.81',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 35,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.btcst,
    contractAddress: {
      97: '',
      56: '0xB6fd2724cc9c90DD31DA35DbDf0300009dceF97d',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.011574',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 34,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bscx,
    contractAddress: {
      97: '',
      56: '0x108BFE84Ca8BCe0741998cb0F60d313823cEC143',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.17361',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 33,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ten,
    contractAddress: {
      97: '',
      56: '0x4A26b082B432B060B1b00A84eE4E823F04a6f69a',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.05787',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 32,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.balbt,
    contractAddress: {
      97: '',
      56: '0x3cc08B7C6A31739CfEd9d8d38b484FDb245C79c8',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.4166',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 31,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.asr,
    contractAddress: {
      97: '',
      56: '0xd18E1AEb349ef0a6727eCe54597D98D263e05CAB',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.01',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 30,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.atm,
    contractAddress: {
      97: '',
      56: '0x68C7d180bD8F7086D91E65A422c59514e4aFD638',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.01',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 29,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.og,
    contractAddress: {
      97: '',
      56: '0xbE65d7e42E05aD2c4ad28769dc9c5b4b6EAff2C7',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.01',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 28,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.reef,
    contractAddress: {
      97: '',
      56: '0x1500fa1afbfe4f4277ed0345cdf12b2c9ca7e139',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '115.74',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 27,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ditto,
    contractAddress: {
      97: '',
      56: '0x624ef5C2C6080Af188AF96ee5B3160Bb28bb3E02',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.01157',
    sortOrder: 999,
    isFinished: false,
  },
  {
    sousId: 26,
    stakingToken: serializedTokens.twt,
    earningToken: serializedTokens.cake,
    contractAddress: {
      97: '',
      56: '0x0554a5D083Abf2f056ae3F6029e1714B9A655174',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.248',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 24,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.juv,
    contractAddress: {
      97: '',
      56: '0x543467B17cA5De50c8BF7285107A36785Ab57E56',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.02',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 25,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.psg,
    contractAddress: {
      97: '',
      56: '0x65aFEAFaec49F23159e897EFBDCe19D94A86A1B6',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.02',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 21,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.vai,
    contractAddress: {
      97: '',
      56: '0x1AD34D8d4D79ddE88c9B6b8490F8fC67831f2CAe',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.104',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 20,
    stakingToken: serializedTokens.bnb,
    earningToken: serializedTokens.cake,
    contractAddress: {
      97: '',
      56: '0x555Ea72d7347E82C614C16f005fA91cAf06DCB5a',
    },
    poolCategory: PoolCategory.BINANCE,
    harvest: true,
    tokenPerBlock: '0.5',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 19,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bnb,
    contractAddress: {
      97: '',
      56: '0x326D754c64329aD7cb35744770D56D0E1f3B3124',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0041',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 18,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.blink,
    contractAddress: {
      97: '',
      56: '0x42Afc29b2dEa792974d1e9420696870f1Ca6d18b',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '23.14',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 17,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.blink,
    contractAddress: {
      97: '',
      56: '0xBb2B66a2c7C2fFFB06EA60BeaD69741b3f5BF831',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '23.14',
    sortOrder: 999,
    isFinished: true,
    enableEmergencyWithdraw: true,
  },
  {
    sousId: 16,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.unfi,
    contractAddress: {
      97: '',
      56: '0xFb1088Dae0f03C5123587d2babb3F307831E6367',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.02893',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 15,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.twt,
    contractAddress: {
      97: '',
      56: '0x9c4EBADa591FFeC4124A7785CAbCfb7068fED2fb',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '5',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 14,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.hard,
    contractAddress: {
      97: '',
      56: '0x90F995b9d46b32c4a1908A8c6D0122e392B3Be97',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.346',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 13,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.broobee,
    contractAddress: {
      97: '',
      56: '0xdc8c45b7F3747Ca9CaAEB3fa5e0b5FCE9430646b',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '12.5',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 12,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.stax,
    contractAddress: {
      97: '',
      56: '0xFF02241a2A1d2a7088A344309400E9fE74772815',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.2',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 11,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.nar,
    contractAddress: {
      97: '',
      56: '0xDc938BA1967b06d666dA79A7B1E31a8697D1565E',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 10,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.nya,
    contractAddress: {
      97: '',
      56: '0x07a0A5B67136d40F4d7d95Bc8e0583bafD7A81b9',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '10',
    isFinished: true,
  },
  {
    sousId: 9,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ctk,
    contractAddress: {
      97: '',
      56: '0x21A9A53936E812Da06B7623802DEc9A1f94ED23a',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.5',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 8,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.twt,
    contractAddress: {
      97: '',
      56: '0xe7f9A439Aa7292719aC817798DDd1c4D35934aAF',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '20',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 7,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.inj,
    contractAddress: {
      97: '',
      56: '0xcec2671C81a0Ecf7F8Ee796EFa6DBDc5Cb062693',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.25',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 248,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ccar,
    contractAddress: {
      97: '',
      56: '0x9e31aef040941E67356519f44bcA07c5f82215e5',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.6093',
  },
  {
    sousId: 262,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ach,
    contractAddress: {
      97: '',
      56: '0xD5668e936B951292Ddf8c84553CC58F85948F816',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    enableEmergencyWithdraw: true,
    sortOrder: 999,
    tokenPerBlock: '7.502',
  },
].filter((p) => !!p.contractAddress[CHAIN_ID])

export default pools
